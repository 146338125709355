import _ from 'lodash'
import { getBrowserLanguage, getBrowserReferrer, getCSRFToken, isSSR } from '@wix/thunderbolt-commons'
import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	BrowserWindow,
	BrowserWindowSymbol,
	CurrentRouteInfoSymbol,
	Experiments,
	ExperimentsSymbol,
	PlatformEnvData,
	PlatformEnvDataProvider,
	PlatformSiteConfig,
	SiteFeatureConfigSymbol,
	ViewerModel,
	ViewerModelSym,
	WixBiSession,
	WixBiSessionSymbol
} from '@wix/thunderbolt-symbols'
import { ISessionManager, SessionManagerSymbol } from 'feature-session-manager'
import { ICurrentRouteInfo, UrlHistoryManagerSymbol, IUrlHistoryManager, RoutingLinkUtilsAPISymbol, IRoutingLinkUtilsAPI } from 'feature-router'
import { IPopupsLinkUtilsAPI, PopupsLinkUtilsAPISymbol } from 'feature-popups'
import { ConsentPolicySymbol, IConsentPolicy } from 'feature-consent-policy'
import { name } from './symbols'

export const consentPolicyEnvDataProvider = withDependencies(
	[ConsentPolicySymbol],
	(consentPolicyApi: IConsentPolicy): PlatformEnvDataProvider => {
		return {
			get platformEnvData() {
				return {
					consentPolicy: {
						details: consentPolicyApi.getCurrentConsentPolicy(),
						header: consentPolicyApi._getConsentPolicyHeader()
					}
				}
			}
		}
	}
)

export const locationEnvDataProvider = withDependencies(
	[named(SiteFeatureConfigSymbol, name), UrlHistoryManagerSymbol],
	(platformSiteConfig: PlatformSiteConfig, urlHistoryManager: IUrlHistoryManager): PlatformEnvDataProvider => {
		return {
			get platformEnvData() {
				const rawUrl = urlHistoryManager.getParsedUrl().href
				return {
					location: {
						...platformSiteConfig.bootstrapData.envData.location,
						rawUrl
					}
				}
			}
		}
	}
)

export const windowEnvDataProvider = withDependencies(
	[BrowserWindowSymbol],
	(window: BrowserWindow): PlatformEnvDataProvider => ({
		platformEnvData: {
			window: {
				isSSR: isSSR(window),
				browserLocale: getBrowserLanguage(window),
				csrfToken: getCSRFToken(window)
			}
		}
	})
)

export const documentEnvDataProvider = withDependencies(
	[BrowserWindowSymbol],
	(window: BrowserWindow): PlatformEnvDataProvider => ({
		platformEnvData: {
			document: {
				referrer: getBrowserReferrer(window)
			}
		}
	})
)
export const biEnvDataProvider = withDependencies(
	[
		named(SiteFeatureConfigSymbol, name),
		BrowserWindowSymbol,
		WixBiSessionSymbol,
		SessionManagerSymbol,
		ViewerModelSym,
		UrlHistoryManagerSymbol,
		ExperimentsSymbol,
		CurrentRouteInfoSymbol,
		optional(PopupsLinkUtilsAPISymbol)
	],
	(
		platformSiteConfig: PlatformSiteConfig,
		window: BrowserWindow,
		wixBiSession: WixBiSession,
		sessionManager: ISessionManager,
		viewerModel: ViewerModel,
		urlHistoryManager: IUrlHistoryManager,
		experiments: Experiments,
		currentRoutingInfo: ICurrentRouteInfo,
		popupsLinkUtilsAPI: IPopupsLinkUtilsAPI
	): PlatformEnvDataProvider => {
		let pageNumber = 0
		const { mode, rollout, fleetConfig } = viewerModel
		const bi = {
			...platformSiteConfig.bootstrapData.envData.bi,
			..._.omit(wixBiSession, 'checkVisibility'),
			visitorId: sessionManager.getVisitorId(),
			siteMemberId: sessionManager.getSiteMemberId(),
			viewerVersion: process.env.browser ? window!.thunderboltVersion : (process.env.APP_VERSION as string),
			rolloutData: rollout,
			fleetConfig
		}

		return {
			// @ts-ignore
			get platformEnvData() {
				const pageId = currentRoutingInfo.getCurrentRouteInfo()?.pageId

				const { href, searchParams } = urlHistoryManager.getParsedUrl()

				const suppressBi = searchParams.has('suppressbi') && searchParams.get('suppressbi') !== 'false'
				if (!pageId) {
					// platform init
					return {
						bi: {
							pageData: {
								pageNumber: 1
							},
							rolloutData: {},
							fleetConfig: {},
							muteFedops: mode.qa || suppressBi
						}
					}
				}

				const currentLightboxId = popupsLinkUtilsAPI?.getCurrentOrPendingPopupId()
				const isLightbox = !!currentLightboxId

				if (!isLightbox) {
					pageNumber++
				}

				const biPageData = {
					pageNumber,
					pageId: currentLightboxId || pageId,
					pageUrl: href,
					isLightbox
				}

				const muteBi = (isSSR(window) || mode.qa || suppressBi) && !experiments.sv_reportTrace
				const muteFedops = mode.qa || suppressBi || isLightbox || (pageNumber > 1 && !experiments['specs.thunderbolt.do_not_mute_apps_fedops_after_in_app_navigation'])

				return {
					bi: {
						...bi,
						pageData: biPageData,
						muteBi,
						muteFedops
					}
				}
			}
		}
	}
)

export const routingEnvDataProvider = withDependencies(
	[RoutingLinkUtilsAPISymbol, CurrentRouteInfoSymbol, UrlHistoryManagerSymbol],
	(routingLinkUtilsAPI: IRoutingLinkUtilsAPI, currentRouteInfo: ICurrentRouteInfo): PlatformEnvDataProvider => {
		return {
			get platformEnvData() {
				const routeInfo = currentRouteInfo.getCurrentRouteInfo()
				const dynamicRouteData = routeInfo?.dynamicRouteData

				const routerEnvData: PlatformEnvData['router'] = {
					routingInfo: routingLinkUtilsAPI.getLinkUtilsRoutingInfo(),
					pageJsonFileName: routeInfo?.pageJsonFileName || ''
				}

				if (dynamicRouteData) {
					routerEnvData.dynamicRouteData = _.pick(dynamicRouteData, ['pageData', 'publicData'])
				}

				return {
					router: routerEnvData
				}
			}
		}
	}
)
