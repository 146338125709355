import { Experiments, PlatformBootstrapData, PlatformEnvData, ViewerModel } from '@wix/thunderbolt-symbols'
import _ from 'lodash'
import { BootstrapData } from '../types'
import { FallbackStrategy, getFallbackOverrideStrategy, SiteAssetsClientConfig } from 'thunderbolt-site-assets-client'

export function createBootstrapData({
	platformBootstrapData,
	viewerModel,
	experiments,
	currentContextId,
	currentPageId,
	commonConfig,
	siteAssetsClientConfig,
	platformEnvData
}: {
	platformBootstrapData: PlatformBootstrapData
	viewerModel: ViewerModel
	experiments: Experiments
	currentContextId: string
	currentPageId: string
	commonConfig: ViewerModel['commonConfig']
	siteAssetsClientConfig: SiteAssetsClientConfig
	platformEnvData: PlatformEnvData
}): BootstrapData {
	const { siteFeaturesConfigs, siteAssets, deviceInfo } = viewerModel
	const sdkFactoriesSiteFeatureConfigs = _.pickBy(siteFeaturesConfigs, (siteConfig, featureName) => featureName.toLowerCase().includes('wixcodesdk'))
	const siteAssetsClientInitParams = {
		...siteAssets,
		deviceInfo,
		siteAssetsClientConfig,
		fallbackStrategy:
			getFallbackOverrideStrategy(experiments, 'clientWorker') || ((experiments['specs.thunderbolt.shouldEnableSACFallbackForClientWorker'] ? 'enable' : 'disable') as FallbackStrategy)
	}

	return {
		currentPageId,
		currentContextId,
		siteAssetsClientInitParams,
		experiments,
		commonConfig,
		platformEnvData,
		sdkFactoriesSiteFeatureConfigs,
		...platformBootstrapData
	}
}
