import _ from 'lodash'
import { IPageProvider, IPageReflector, PageProviderSymbol } from 'feature-pages'
import { IPopupsLinkUtilsAPI, PopupsLinkUtilsAPISymbol } from 'feature-popups'
import { multi, named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client'
import {
	CompEventsRegistrarSym,
	ComponentsStylesOverridesSymbol,
	Experiments,
	ExperimentsSymbol,
	IAppWillLoadPageHandler,
	ICompEventsRegistrar,
	IComponentsStylesOverrides,
	ILanguage,
	ILogger,
	IPlatform,
	IPropsStore,
	LanguageSymbol,
	LoggerSymbol,
	PlatformEnvDataProvider,
	PlatformEvnDataProviderSymbol,
	PlatformSiteConfig,
	Props,
	PropsMap,
	SdkHandlersProvider,
	SiteAssetsClientSym,
	SiteFeatureConfigSymbol,
	ViewerModel,
	ViewerModelSym,
	WixBiSession,
	WixBiSessionSymbol,
	WixCodeSdkHandlersProviderSym
} from '@wix/thunderbolt-symbols'
import { PlatformInitializer, PlatformWarmupData, ViewerAPI } from './types'
import { name, PlatformInitializerSym } from './symbols'
import { CommonConfigSymbol, ICommonConfig } from 'feature-common-config'
import { DebugApis, TbDebugSymbol } from 'feature-debug'
import { IPageNumber, PageNumberSymbol } from 'feature-business-logger'
import { IMultilingualLinkUtilsAPI, MultilingualLinkUtilsAPISymbol } from 'feature-multilingual'
import { IWarmupDataProvider, WarmupDataProviderSymbol } from 'feature-warmup-data'
import { createBootstrapData } from './viewer/createBootstrapData'

export const Platform = withDependencies(
	[
		PlatformInitializerSym,
		named(SiteFeatureConfigSymbol, name),
		ViewerModelSym,
		Props,
		LoggerSymbol,
		WixBiSessionSymbol,
		SiteAssetsClientSym,
		CompEventsRegistrarSym,
		LanguageSymbol,
		ExperimentsSymbol,
		optional(PopupsLinkUtilsAPISymbol),
		optional(MultilingualLinkUtilsAPISymbol),
		PageProviderSymbol,
		multi(WixCodeSdkHandlersProviderSym),
		CommonConfigSymbol,
		multi(PlatformEvnDataProviderSymbol),
		ComponentsStylesOverridesSymbol,
		PageNumberSymbol,
		WarmupDataProviderSymbol,
		optional(TbDebugSymbol)
	],
	(
		platformRunnerContext: PlatformInitializer,
		platformSiteConfig: PlatformSiteConfig,
		viewerModel: ViewerModel,
		propsStore: IPropsStore,
		logger: ILogger,
		wixBiSession: WixBiSession,
		siteAssetsClient: SiteAssetsClientAdapter,
		compEventsRegistrar: ICompEventsRegistrar,
		language: ILanguage,
		experiments: Experiments,
		popupsLinkUtilsAPI: IPopupsLinkUtilsAPI,
		multilingualLinkUtilsAPI: IMultilingualLinkUtilsAPI,
		pageProvider: IPageProvider,
		siteHandlersProviders: Array<SdkHandlersProvider>,
		commonConfigAPI: ICommonConfig,
		platformEnvDataProviders: Array<PlatformEnvDataProvider>,
		componentsStylesOverrides: IComponentsStylesOverrides,
		pageNumber: IPageNumber,
		warmupDataProvider: IWarmupDataProvider,
		debugApi?: DebugApis
	): IAppWillLoadPageHandler & IPlatform => {
		const siteHandlers = siteHandlersProviders.map((siteHandlerProvider) => siteHandlerProvider.getSdkHandlers())
		function getHandlers(page: IPageReflector) {
			const pageHandlersProviders = page.getAllImplementersOf<SdkHandlersProvider>(WixCodeSdkHandlersProviderSym)
			const pageHandlers = pageHandlersProviders.map((pageHandlerProvider) => pageHandlerProvider.getSdkHandlers())
			return Object.assign({}, ...pageHandlers, ...siteHandlers)
		}

		function getPlatformEnvData() {
			return Object.assign({}, ...platformEnvDataProviders.map((envApiProvider) => envApiProvider.platformEnvData))
		}

		const {
			bootstrapData: siteConfigBootstrapData,
			landingPageId,
			isChancePlatformOnLandingPage,
			debug: { disablePlatform }
		} = platformSiteConfig

		siteConfigBootstrapData.platformServicesAPIData.link.popupPages = popupsLinkUtilsAPI?.getPopupPages()
		siteConfigBootstrapData.platformServicesAPIData.link.multilingual = multilingualLinkUtilsAPI?.getMultilingualInfo()

		platformRunnerContext.initPlatformOnSite({
			platformEnvData: getPlatformEnvData(),
			appsUrlData: siteConfigBootstrapData.appsUrlData,
			componentSdksClientUrl: siteConfigBootstrapData.componentSdksClientUrl,
			componentSdksServerUrl: siteConfigBootstrapData.componentSdksServerUrl
		})

		const appWillLoadPage = async ({ pageId: currentPageId, contextId }: Parameters<IAppWillLoadPageHandler['appWillLoadPage']>[0]) => {
			if (disablePlatform || (currentPageId === landingPageId && !isChancePlatformOnLandingPage)) {
				return
			}

			// Getting envData on each navigation so it can depend on currentUrl.
			const platformEnvData = getPlatformEnvData()

			if (!platformEnvData.bi.muteFedops) {
				logger.interactionStarted('platform')
			}

			const handlersPromise = Promise.all([pageProvider(contextId, currentPageId), pageProvider('masterPage')]).then(([page, masterPage]) => ({
				masterPageHandlers: getHandlers(masterPage),
				pageHandlers: getHandlers(page)
			}))

			const bootstrapData = createBootstrapData({
				platformEnvData,
				platformBootstrapData: siteConfigBootstrapData,
				viewerModel,
				experiments,
				currentContextId: contextId,
				currentPageId,
				commonConfig: commonConfigAPI.getCommonConfig(),
				siteAssetsClientConfig: siteAssetsClient.getInitConfig()
			})

			const viewerAPI: ViewerAPI = {
				updateProps: (partialProps: PropsMap) => {
					platformRunnerContext.updateProps(partialProps)
				},
				updateStyles: (overrideStyles: { [compId: string]: object }) => {
					const styles = _(overrideStyles)
						.mapValues((compStyles, compId) => ({ ...componentsStylesOverrides.getCompStyle(compId), ...compStyles }))
						.value()
					platformRunnerContext.updateStyles(styles)
				},
				invokeSdkHandler: async (pageId: string, functionName: string, ...args: any) => {
					const { masterPageHandlers, pageHandlers } = await handlersPromise
					const handlers = pageId === 'masterPage' ? masterPageHandlers : pageHandlers
					if (!_.isFunction(handlers[functionName])) {
						logger.captureError(new Error('handler does not exist in page'), {
							tags: { platform: true, handler: functionName },
							extra: { pageId, contextId }
						})
						return
					}
					return handlers[functionName](...args)
				}
			}

			if (debugApi) {
				debugApi.platform.logBootstrapMessage(contextId, bootstrapData)
			}

			await platformRunnerContext.runPlatformOnPage(bootstrapData, viewerAPI)
			if (!platformEnvData.bi.muteFedops) {
				logger.interactionEnded('platform')
			}
		}

		let platformReadyPromise = Promise.resolve()
		return {
			appWillLoadPage({ pageId, contextId }) {
				const appWillLoadPagePromise = appWillLoadPage({ pageId, contextId })

				if (process.env.browser && pageNumber.getPageNumber() === 1 && experiments['specs.thunderbolt.do_not_wait_for_platform_on_first_render2']) {
					platformReadyPromise = appWillLoadPagePromise
					return warmupDataProvider.getWarmupData<PlatformWarmupData>('platform').then((platformWarmupData) => {
						if (platformWarmupData) {
							// in first render after ssr we don't need to wait for platform before hydrating since we render with props from ssr
							return
						}

						// either client side fallback, or navigating to a protected page.
						// in both cases we want to wait for platform before rendering.
						return appWillLoadPagePromise
					})
				}
				return appWillLoadPagePromise
			},
			ready() {
				return platformReadyPromise
			}
		}
	}
)
